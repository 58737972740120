import Image from "next/image";
import { useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";

type Props = {
  imageData: ImageSingleType | ImageArrayType;
};

const ImgSection = ({ imageData }: Props) => {
  const imageRenderType = useMemo(() => {
    if (imageData.imageType === "array") {
      if (imageData.images.length > 4) {
        return (
          <Swiper
            spaceBetween={10}
            autoplay={{
              delay: 2000,
            }}
            slidesPerView={"auto"}
            modules={[Autoplay]}
            loop={true}
          >
            {imageData.images.map(v => (
              <SwiperSlide className="!w-[130px] !h-[130px]" key={v.alt}>
                <Image key={v.alt} src={v.src} width={130} height={130} alt={v.alt} />
              </SwiperSlide>
            ))}
          </Swiper>
        );
      } else {
        return (
          <div className="flex gap-x-2.5 justify-center">
            {imageData.images.map(v => (
              <Image key={v.alt} src={v.src} width={120} height={130} alt={v.alt} />
            ))}
          </div>
        );
      }
    } else {
      return <Image src={imageData.image.src} alt={imageData.image.alt} width={222} height={260} />;
    }
  }, [imageData]);

  return <>{imageRenderType}</>;
};

export default ImgSection;

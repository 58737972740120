import ImgSection from "./img-section";

type Props = {
  content: string[];
  subContent?: string;
  imageData: ImageSingleType | ImageArrayType;
  footerContent?: string;
  imageSectionDescription?: string;
};

const CheckpointSection = ({ content, subContent, imageData, footerContent, imageSectionDescription }: Props) => {
  return (
    <div className={"mt-4  w-full"}>
      <p className="mb-6 font-medium text-[15px] leading-[1.5] text-[#3A3B40]">
        {content.map((v, i) => (
          <span key={v}>
            {v}
            <br />
          </span>
        ))}
      </p>

      <div className="w-full px-5">
        <ImgSection imageData={imageData} />
      </div>

      {imageSectionDescription ? (
        <p className="text-[#8B8D94] text-[13px] leading-[1.4] mt-3.5 ">{imageSectionDescription}</p>
      ) : null}

      <p className="my-6 font-medium text-[15px] leading-[1.5] text-[#3A3B40] whitespace-pre-wrap">{subContent}</p>

      {footerContent && (
        <p className="mb-2 mt-6 font-bold text-[15px] leading-[1.5] text-[#3A3B40] whitespace-pre-wrap">
          {footerContent}
        </p>
      )}
    </div>
  );
};

export default CheckpointSection;

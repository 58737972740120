type Props = {
  className?: string;
  color?: string;
};

export const ArrowRightIcon = ({ className, color }: Props) => {
  return (
    <svg
      className={className}
      width="16"
      height="26"
      viewBox="0 0 16 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 2L13 13L2 24" stroke={`${color ? color : "#12B564"}`} strokeWidth="3" />
    </svg>
  );
};

export const ArrowRightIconV2 = ({ className, color }: Props) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.40125 19.8013C8.17125 19.8013 7.94125 19.7113 7.76625 19.5363C7.41625 19.1862 7.41625 18.6163 7.76625 18.2613L13.8762 12.1512L7.76625 6.03625C7.41125 5.68625 7.41125 5.11625 7.76625 4.76625C8.11625 4.41125 8.68625 4.41125 9.03625 4.76625L15.7863 11.5162C16.1363 11.8662 16.1363 12.4363 15.7863 12.7913L9.03625 19.5363C8.86125 19.7113 8.63125 19.8013 8.40125 19.8013Z"
        fill={`${color ? color : "white"}`}
      />
    </svg>
  );
};

import { MouseEvent } from "react";

import { secondaryText, speechBalloonsData } from "constants/landing";
import IsCounselFlagCheckButton from "./counsel-button/isCounselFlagCheckButton";
import SpeechBalloon from "./speech-balloon/speech-balloon";
import CounselButton from "feature/landing/counsel-button/counsel-button";

type Props = {
  counselProgress: CounselProgress;
  handleStartCounsel: (e: MouseEvent<HTMLAnchorElement>) => Promise<void>;
  subHref: string;
  primaryText: string;
  isLoggedIn: boolean;
};
const SpeechBalloonsSection = ({ counselProgress, handleStartCounsel, subHref, primaryText, isLoggedIn }: Props) => {
  return (
    <section className="bg-[#E4F2E8]/[.2] text-center pt-[60px] pb-[40px]">
      <h1 className="font-bold text-[#262626] text-[24px] leading-normal">
        혹시 하우스 시공 알아보실 때
        <br />
        <span className="text-[#0BB25F]">이런 고민 하셨었나요?</span>
      </h1>
      <div className="mt-[32px] flex flex-col relative mx-3">
        {speechBalloonsData.map((v, i) => (
          <SpeechBalloon
            key={`speech-balloon-${i}`}
            text={v.text}
            variant={i % 2 === 0 ? "left" : "right"}
            layout={v.layout}
            src={v.src}
            className={i !== speechBalloonsData.length - 1 ? "mb-5" : ""}
          ></SpeechBalloon>
        ))}
      </div>

      <div className="mt-[60px]">
        <h1 className="font-bold text-[#262626] text-[24px] leading-normal">
          이 고민들 이제
          <br />
          <span className="text-[#0BB25F]">팜모닝이 해결해드릴게요!</span>
        </h1>
        <div className="mt-4">
          지금 간편 견적을 통해
          <br />
          원하는 온실을 찾아보고 무료 상담도 받아보세요
        </div>
      </div>

      <div className="mx-5 mt-8 flex gap-x-2 justify-center">
        {isLoggedIn ? (
          <IsCounselFlagCheckButton
            flag={counselProgress.lastCounselFlag}
            handleStartCounsel={handleStartCounsel}
            primaryText={primaryText}
            type={"sub"}
            secondaryText={secondaryText}
            subHref={subHref}
          />
        ) : (
          <CounselButton isFlag={true} variant={"subPrimary"} className={"!w-full"} text={primaryText} />
        )}
      </div>
    </section>
  );
};

export default SpeechBalloonsSection;

import Image from "next/image";
import { ROUTE } from "constants/route";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";

import styles from "./landing-v2.module.css";
import CheckpointSection from "../../components/layout/landing/checkpoint-section";
import { checkPoint2, preferentialConditions, secondaryText } from "constants/landing";
import SpeechBalloonsSection from "./speech-balloons-section";
import ImageSection from "components/layout/landing/img-section";
import { counselProgressAtom } from "store/global";
import IsCounselFlagCheckButton from "./counsel-button/isCounselFlagCheckButton";
import CounselButton from "feature/landing/counsel-button/counsel-button";

export default function Landing({ handleStartCounsel, primaryText, isLoggedIn }: LandingByEnvironmentProps) {
  const [counselProgress] = useAtom(counselProgressAtom);
  const [innerHeight, setInnerHeight] = useState(0);

  const handleResize = () => {
    const vh = window.innerHeight * 0.01;
    vh !== 0 && document.documentElement.style.setProperty("--vh", `${vh}px`);
    setInnerHeight(window.innerHeight);
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className={`m-auto max-w-2xl ${styles["wrapper"]}`}>
        <section className={`${styles["section-notice-wrap"]} ${innerHeight < 1000 ? styles["h-inner-screen"] : ""}`}>
          <div className={styles["header-logo"]}>
            <Image src="/icons/farmmorning-w-logo.svg" width="120" height="40" alt="farmmorning-logo" />
          </div>
          <div
            className={`flex items-center w-full flex-col text-white absolute ${
              innerHeight < 1200 ? "top-28 left-1/2 -translate-x-1/2" : "top-44 left-1/2 -translate-x-1/2"
            }`}
          >
            <h4 className="font-bold text-[20px] sm:text-[30px] text-center">비닐 하우스, 농막, 스마트팜 등</h4>
            <h1 className="font-black text-[28px] sm:text-[40px] leading-normal mt-2 text-center">
              하우스 견적을 직접 해보고
              <br />
              전문가 무료 상담도 받아보세요
            </h1>
            <h5 className="font-semibold text-[18px] 2xl:text-[26px] text-center mt-3.5">
              팜모닝 전문가들이 상세 견적부터
              <br />
              시공 상담까지 진행해 드려요
            </h5>
          </div>

          <div className={styles["section-notice-btn-wrap"]}>
            {isLoggedIn ? (
              <IsCounselFlagCheckButton
                type={"main"}
                flag={counselProgress.lastCounselFlag}
                handleStartCounsel={handleStartCounsel}
                primaryText={primaryText}
                secondaryText={secondaryText}
                subHref={ROUTE.LIST}
              />
            ) : (
              <CounselButton isFlag={true} variant={"primary"} className={"!w-full"} text={primaryText} />
            )}
          </div>
        </section>
        <SpeechBalloonsSection
          isLoggedIn={isLoggedIn}
          counselProgress={counselProgress}
          handleStartCounsel={handleStartCounsel}
          subHref={ROUTE.LIST}
          primaryText={primaryText}
        />
        <section className={styles["section-third-wrap"]}>
          <div className={styles["section-third-content-wrap"]}>
            <h3 className="px-2.5 bg-[#DFF7EA] py-1.5 text-[#0BB25F] font-bold text-[15px] leading-[1.5] rounded-[100px]">
              체크 포인트 1
            </h3>
            <h1 className="text-[#262626] font-bold text-[22px] leading-[1.4] mt-4">
              팜모닝 하우스 견적 상담
              <br />
              <span className="text-[#0BB25F]">서비스는 정확히 무엇인가요?</span>
            </h1>
            <p className="font-medium text-[15px] leading-[1.5]  mt-4">
              간편 견적을 통해 직접 원하는 하우스를 찾고 <br />
              금액 범위를 즉시 확인할 수 있습니다.
            </p>
            <div className="mt-8">
              <Image src="/images/section-3-1.png" width={280} height={199} alt="section-3-1" />
            </div>
            <div className="mt-8 text-[#3A3B40] leading-[1.5] text-[15px]">
              시공 전문업체들만 사용할 수 있는 <br />
              전문 설계 프로그램을 통해 <br />
              정확한 하우스 견적을 받아 보실 수 있습니다.
            </div>
            <div className="mt-8">
              <Image src="/images/section-3-2.png" width={280} height={199} alt="section-3-2" />
            </div>

            <div className="mt-8 text-[#3A3B40] leading-[1.5] text-[15px]">
              시공 상담부터 현장 방문, 자재 구입,
              <br />
              보조 사업 서류 진행까지 한 번에 진행이 가능합니다.
            </div>
          </div>

          <div className={styles["section-third-content-wrap"]}>
            <h3 className="px-2.5 bg-[#DFF7EA] py-1.5 text-[#0BB25F] font-bold text-[15px] leading-[1.5] rounded-[100px]">
              체크 포인트 2
            </h3>
            <h1 className="text-[#262626] font-bold text-[22px] leading-[1.4] mt-4">
              국내 최고의 전문가들이
              <br />
              <span className="text-[#0BB25F]">직접 시공 관리해 드려요!</span>
            </h1>

            {checkPoint2.map((v, i) => (
              <CheckpointSection
                key={`check-section-${i}`}
                content={[...v.content]}
                imageData={v.imageData}
                footerContent={v.footerContent}
                subContent={v.subContent}
              />
            ))}
          </div>

          <div className={`${styles["section-third-content-wrap"]} !mb-0 !pb-2.5`}>
            <h3 className="px-2.5 bg-[#DFF7EA] py-1.5 text-[#0BB25F] font-bold text-[15px] leading-[1.5] rounded-[100px]">
              우대조건
            </h3>
            <h1 className="text-[#262626] font-bold text-[22px] leading-[1.4] mt-4">
              {preferentialConditions.title[0]}
              <br />
              <span className="text-[#0BB25F]"> {preferentialConditions.title[1]}</span>
            </h1>
            <p className="font-medium text-[15px] leading-[1.5] mt-4 whitespace-pre-wrap">
              {preferentialConditions.content}
            </p>
            <div className="mt-8 w-full">
              <ImageSection imageData={preferentialConditions.imageData} />
            </div>

            <div className="mx-5 p-4 rounded-2xl bg-[#F7F8FA] mt-7 mb-10 text-left">
              {preferentialConditions.footerContent.map((v, i) => (
                <div className="flex flex-col mb-2 last:mb-0" key={`section_4_${i}`}>
                  <span className="section-4-dot font-bold text-[#3A3B40]">{v}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-10 w-full flex justify-center gap-x-2">
            {isLoggedIn ? (
              <IsCounselFlagCheckButton
                type={"sub"}
                flag={counselProgress.lastCounselFlag}
                handleStartCounsel={handleStartCounsel}
                primaryText={primaryText}
                secondaryText={secondaryText}
                subHref={ROUTE.LIST}
              />
            ) : (
              <CounselButton isFlag={true} variant={"subPrimary"} className={"!w-full"} text={primaryText} />
            )}
          </div>
        </section>
      </div>
    </>
  );
}

import { TextBody } from "@greenlabs/formula-components";
import Image from "next/image";
import { useEffect, useState } from "react";

type Variant = "left" | "right";

interface Layout {
  min: WidthHeight;
  max: WidthHeight;
  base: WidthHeight;
}

type WidthHeight = { width: number; height: number };
interface Props {
  variant: Variant;
  text: string;
  layout: Layout;
  className?: string;
  src: string;
}

const wrapperVariant = {
  left: "",
  right: "self-end",
};

const textVariant = {
  left: "ml-5 sm:ml-9 text-left",
  right: "mr-5 sm:mr-9 text-left right-0",
};

const SpeechBalloon = (props: Props) => {
  const [widthHeight, setWidthHeight] = useState<WidthHeight>(props.layout.min);

  useEffect(() => {
    const calcWidth = (width: number, layout: Layout) => {
      if (width < 640) {
        return layout.base;
      } else if (width < 361) {
        return layout.min;
      } else {
        return layout.max;
      }
    };
    window.addEventListener("resize", () => {
      setWidthHeight(calcWidth(document.body.clientWidth, props.layout));
    });

    return () => {
      setWidthHeight(calcWidth(document.body.clientWidth, props.layout));
    };
  }, [props]);

  return (
    <>
      <div className={`relative flex ${wrapperVariant[props.variant]} ${props.className || ""}  items-center `}>
        <Image
          src={props.src}
          alt={`${props.variant}-speech-balloon`}
          width={widthHeight.width}
          height={widthHeight.height}
        />
        <TextBody
          weight="medium"
          color="white"
          className={`whitespace-pre-wrap absolute  ${
            textVariant[props.variant]
          } -xs-mr-0 -xxs:!text-sm -xs:!text-base -sm:!text-xl  -md:!text-2xl md:!text-2xl -xs:left-1/2 -xs:-translate-x-1/2 w-max -xs:ml-0 `}
        >
          {props.text}
        </TextBody>
      </div>
    </>
  );
};

export default SpeechBalloon;

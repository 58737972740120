interface CheckPointContent {
  title?: string[];
  content: string[] | string;
  imageData: ImageArrayType | ImageSingleType;
  imageSectionDescription?: string;
  subContent: string;
  footerContent?: string;
}

interface PreferentialConditionContent {
  title?: string[];
  content: string[] | string;
  imageData: ImageArrayType | ImageSingleType;
  subContent: string;
  footerContent?: string[];
}

export const checkPoint2: CheckPointContent[] = [
  // {
  //   title: ["10년 이상의 하우스 설계 경력,", "팜모닝 온실 설계 전문가"],
  //   content: [
  //     "현재 팜모닝 본사에서 활동하는",
  //     "온실 설계 전문가는고 10년 이상의 농자재 유통 및",
  //     "하우스 설계 경력을 가지고 비닐 하우스 전문",
  //     "설계 프로그램을 연구/개발하고 있습니다.",
  //     "이 경험을 바탕으로 하우스 시공에 필요한",
  //     "농자재 가격 견적까지 정확하게 상담해드립니다.",
  //   ],
  //   imageData: {
  //     imageType: "array",
  //     images: [
  //       { src: "/images/design-expert-1.png", alt: "design-expert-1" },
  //       { src: "/images/design-expert-2.png", alt: "design-expert-2" },
  //     ],
  //   },
  //   subContent: "팜모닝 온실 설계 전문가",
  // },
  {
    content: [
      "검증된 하우스 전문가가",
      "견적부터 시공까지 전화로 상담해드리고,",
      "필요한 경우 현장 방문도 해드립니다.",
    ],

    imageData: {
      imageType: "array",
      images: [
        { src: "/images/design-counsel-landing/expert-1.png", alt: "construct-expert-1" },
        { src: "/images/design-counsel-landing/expert-2.png", alt: "construct-expert-2" },
        { src: "/images/design-counsel-landing/expert-3.png", alt: "construct-expert-3" },
        { src: "/images/design-counsel-landing/expert-4.png", alt: "construct-expert-4" },
        { src: "/images/design-counsel-landing/expert-5.png", alt: "construct-expert-5" },
        { src: "/images/design-counsel-landing/expert-6.png", alt: "construct-expert-6" },
        { src: "/images/design-counsel-landing/expert-7.png", alt: "construct-expert-7" },
        { src: "/images/design-counsel-landing/expert-8.png", alt: "construct-expert-8" },
      ],
    },

    imageSectionDescription: "온실 시공 상담 전문가",

    subContent:
      "팜모닝으로 부터 검증된 협력사 전문가 입니다. \n 지역별 최저가를 제공하고, 평균 10년 이상 \n 신뢰할 수 있는 시공을 제공하고 있는 업체입니다.",

    footerContent: "하우스 전문가는 모든 하우스 시공에 대하여 \n 1년 이상의 A/S를 지원합니다.",
  },
];

export const speechBalloonsData = [
  {
    text: "믿고 맡길 수 있는 시공 업체를 찾고 있어요.",
    layout: {
      min: {
        width: 280,
        height: 42,
      },
      base: {
        width: 400,
        height: 60,
      },
      max: {
        width: 480,
        height: 80,
      },
    },

    src: "/images/web-landing/left-speech-balloon.png",
  },
  {
    text: "내게 필요한 용도에 맞는 온실을 찾고 있어요.\n내재해형인지, 보험 적용은 가능한지 알고 싶어요.",
    layout: {
      min: {
        width: 310,
        height: 70,
      },
      base: {
        width: 430,
        height: 90,
      },
      max: {
        width: 530,
        height: 110,
      },
    },
    src: "/images/web-landing/right-speech-balloon-big.png",
  },
  {
    text: "온실을 짓는데 필요한 자재를 정확하게 알고 싶어요.",
    layout: {
      min: {
        width: 320,
        height: 42,
      },
      base: {
        width: 430,
        height: 60,
      },
      max: {
        width: 550,
        height: 80,
      },
    },
    src: "/images/web-landing/left-speech-balloon.png",
  },
  {
    text: "거품을 뺀 가격의 합리적인 견적을 받고 싶어요.",
    layout: {
      min: {
        width: 300,
        height: 40,
      },
      base: {
        width: 400,
        height: 60,
      },
      max: {
        width: 500,
        height: 80,
      },
    },
    src: "/images/web-landing/right-speech-balloon.png",
  },
];

export const preferentialConditions: PreferentialConditionContent = {
  title: ["팜모닝 하우스 전문가", "견적 상담 우대조건"],
  content:
    "견적 상담 지역을 전국으로 확장하고 있습니다.\n 검증된 전문가를 연결시켜 드리기 위해 \n 최선을 다하겠습니다.",
  imageData: {
    imageType: "single",
    image: { src: "/images/last-section.png", alt: "last-section", width: 222, height: 260 },
  },

  subContent: "",
  footerContent: [
    "경기남부, 강원남부, 충청남도, 전라북도, 전라남도, 경상북도 시공건",
    "하우스 시공 일정이 3개월 이내인 경우",
  ],
};

export const secondaryText = "신청 내역";

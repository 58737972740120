import Link from "next/link";
import { MouseEvent } from "react";

import { ArrowRightIconV2 } from "components/icons/arrow-right-icon";
import { ROUTE } from "constants/route";

type Props = {
  handleClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  isFlag: boolean;
  variant: "primary" | "secondary" | "subPrimary" | "subSecondary";
  text: string;
  className?: string;
  href?: string;
  dataCy?: string;
};

const variantsClass = {
  primary: "border-2 border-[#0bb25f] text-[22px] text-green-600",
  secondary: "border-2 border-[#1f2024] text-[#1f2024] text-[22px]",
  subPrimary: "bg-[#0BB25F] text-white text-[19px]",
  subSecondary: "text-[19px]",
};

const CounselButton = ({ handleClick, isFlag, variant, text, className = "", href = ROUTE.INDEX, dataCy }: Props) => {
  return (
    <Link href={href}>
      <a
        className={`rounded-[12px] font-bold leading-normal inline-flex items-center bg-white w-1/2 cursor-pointer justify-center py-4 shadow-[0_0_10px_rgba(0,0,0,0.25)] ${variantsClass[variant]} ${className}`}
        onClick={handleClick}
        data-cy={dataCy}
      >
        <span>{text}</span>
        {!isFlag && (
          <ArrowRightIconV2
            className="ml-3 inline-block"
            color={`${variant === "primary" ? "#0BB25F" : ""}`}
          ></ArrowRightIconV2>
        )}
      </a>
    </Link>
  );
};

export default CounselButton;

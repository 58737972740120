import { MouseEvent } from "react";

import CounselButton from "./counsel-button";

type Props = {
  flag: boolean;
  handleStartCounsel: (e: MouseEvent<HTMLAnchorElement>) => Promise<void>;
  primaryText: string;
  secondaryText: string;
  type: "main" | "sub";
  subHref: string;
};

const IsCounselFlagCheckButton = ({ flag, handleStartCounsel, primaryText, secondaryText, subHref, type }: Props) => {
  return (
    <>
      {flag && (
        <CounselButton
          href={subHref}
          isFlag={flag}
          dataCy="counsel-list-btn"
          variant={type === "main" ? "secondary" : "subSecondary"}
          text={secondaryText}
        />
      )}

      <CounselButton
        handleClick={handleStartCounsel}
        isFlag={flag}
        dataCy="counsel-start-btn"
        variant={type === "main" ? "primary" : "subPrimary"}
        className={!flag ? "!w-full" : ""}
        text={primaryText}
      />
    </>
  );
};

export default IsCounselFlagCheckButton;
